<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <div class="box-card">
                 <!-- 按钮 -->
                <el-row style="height:40px;">
                    <el-button type="primary" class="buttons" @click="exportData">导出</el-button>
                </el-row>
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" >
                    <el-form-item label="创建时间">
                        <div class="block">
                              <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker 
                                v-model="req.start"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetime"
                                placeholder="开始时间">
                                </el-date-picker>
                            </div>
                            |&nbsp;&nbsp;&nbsp;&nbsp;
                            <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker 
                                v-model="req.end"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetime"
                                placeholder="结束时间">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                            placeholder="请输入关键词搜索"
                            prefix-icon="el-icon-search"
                            v-model="req.keywords"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button  type="primary" @click="onSubmit">搜索</el-button>
                        <el-button  @click="reset">重置</el-button>
                    </el-form-item>
                </el-form>

                <el-form :inline="true" >
                    <el-form-item label="采购数量合计：">
                        <span style="color:#f00;">{{showData.purchase_cout}} </span>
                    </el-form-item>
                    <el-form-item label=" 采购金额合计：">
                        <span style="color:#f00;">{{showData.purchase_total_amount}} 元</span>
                    </el-form-item>
                </el-form>
                
                <!-- 表格 -->
                <el-table
                        ref="multipleTable"
                        :height="__tableHeight__"
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width: 96%;text-align: center">
                    <el-table-column align="center" type="selection" width="65 "> </el-table-column>
                    <el-table-column label="制单时间"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.create_time }}</template>
                    </el-table-column>
                    <el-table-column label="单据编号"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.number}}</template>
                    </el-table-column>
                    <el-table-column label="供应商"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.supplier_name }}</template>
                    </el-table-column>
                    <el-table-column label="门店"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.shop_name }}</template>
                    </el-table-column>
                    <el-table-column label="仓库"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.warehouse_name }}</template>
                    </el-table-column>
                    <el-table-column label="结算方式"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.settlement_method_name }}</template>
                    </el-table-column>
                    <el-table-column label="商品编码"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.product_code }}</template>
                    </el-table-column>

                    <el-table-column label="商品名称"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.product_name }}</template>
                    </el-table-column>

                    <el-table-column label="规格"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.specifications }}</template>
                    </el-table-column>
                    <el-table-column label="单位"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.goods_unit_name }}</template>
                    </el-table-column>

                    <el-table-column label="数量"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.num }}</template>
                    </el-table-column>
                    <el-table-column label="单价（元）"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.price }}</template>
                    </el-table-column>

                    <el-table-column label="采购金额（元）"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.total_amount }}</template>
                    </el-table-column>

                    <el-table-column label="经办人"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.username }}</template>
                    </el-table-column>

                    <el-table-column label="备注"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.remarks }}</template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <el-dialog
            :modal-append-to-body="false"
            title="导出数据"
            :visible.sync="exportExcelFlag"
            v-if="exportExcelFlag"
            width="60%"
            center>
                <div style="height:50px;width:100%;text-align:center;">
                    <i class="el-icon-loading" v-if="exportStatus===1" style="font-size:50px;color:#000;"></i>
                    <i class="el-icon-success" v-if="exportStatus===2" style="font-size:50px;color:green;"></i>
                </div>
                <div v-if="exportStatus===1" style="height:50px;width:100%;text-align:center;font-size:18px;line-height:50px;">
                    数据正在导出...稍后会自动下载，请留意下载工具
                </div>
                <div v-if="exportStatus===2" style="height:50px;width:100%;text-align:center;font-size:18px;line-height:50px;">
                    数据已导出完毕，本窗口即将关闭
                </div>
        </el-dialog>

        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="添加员工"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        width="60%"
        center>
            <createShare  :id="currentId" @fleshList="getList" @shareExit="shareExit"></createShare>
        </el-dialog>
        <!-- 页码 -->
        <div class="pageDiv" style="">
            <el-pagination
                style="margin:10px 75px 10px 0;"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
            </el-pagination>
        </div>
        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="分享列表"
        :visible.sync="shareListFlag"
        v-if="shareListFlag"
        width="80%"
        center>
            <shareList :id="currentId" ></shareList>
        </el-dialog>
    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";

let d = new Date();
let start = d.getFullYear()+'-'+tools.getMM()+'-'+'01' + ' 00:00:00'; 
let end = d.getFullYear()+'-'+tools.getNextMM()+'-'+'01' + ' 00:00:00'; 

export default {
props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            //上传图片列表地址
            uploadImages: [

            ],
            shopList:[],
            createShareFlag:false,
            shareListFlag:false,
            currentId:0,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            statusList:[],
            req:{
                limit:30,
                page:1,
                keywords:'',
                shop_id:'',
                start:start,
                end:end,
            },
            showData:{},
            exportStatus: 1,
            exportExcelFlag: false
        }
    },
    components:{
        createShare,shareListFlag
    },
    methods:{
        setPrice(price){
            return tools.setPrice(price);
        },
        onSubmit(){
            this.getList();
        },
        reset(){
            this.req = {
                limit:30,
                page:1,
                keywords:'',
                shop_id:'',
                start:'',
                end:''
            };
        },
        //导出Excel
        exportData() {
            this.exportExcelFlag = true;
            apis.purchase_report_index_excel(this.req).then(res => {
                this.exportStatus = 2;
                let blob = new Blob([res]);
                let url = URL.createObjectURL(blob);
                setTimeout(() => {
                    this.exportExcelFlag = false;
                    this.exportStatus = 1;
                }, 2500);
                // 下载该blob链接
                tools.downloadFile(url, document.title + tools.getDateTime() + '.xls');
            }).catch(err => {
                console.log(err);
                // this.exportStatus = 3;
            });
        },
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            // 数据
            apis.purchase_report_index(this.req).then((res) => {
                this.tableData = res.data.data;
                this.showData = res.data;
                this.showData.purchase_total_amount = tools.setPrice(this.showData.purchase_total_amount);

                this.tableData.forEach(item=>{
                    item.price = tools.setPrice(item.price);
                    item.specifications = item.specifications.join(',');
                    item.total_amount = tools.setPrice(item.total_amount);
                })
                this.total = res.data.total;
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        shareExit(){
            this.createShareFlag = false;
            this.shareListFlag = false;
        },
        remove(id, key) {
            this.$confirm('确定删除此采购单?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                apis.staffDelete({ id: id}).then((info) => {
                    if (info.code == 200) { 
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        edit(id){
            this.createShareFlag = true;
            this.currentId = id;
        }
    },
    created(){
        this.getList();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>